import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc, getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  updateDoc,
  where
} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getDownloadURL, getStorage, ref} from 'firebase/storage';
import * as nanoid from 'nanoid';
import {cloneDeep, find, first, last, merge, pick, set, uniq, omitBy, isNil} from 'lodash'
import {ALMOST_BLACK, WHITE} from '../model/colors';
import {Field} from '../model/fields';
import {
  ALIGN_LEFT,
  DECORATION_NONE,
  FONTS, lookupFont, MICROSOFT_FONTS, STYLE_ITALIC,
  STYLE_NORMAL,
  TRANSFORM_NONE,
  WEIGHT_BOLD,
  WEIGHT_NORMAL
} from '../model/fonts';
import {Sheet, SheetTemplate, Template} from '../model/objects';
import {ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND} from '../model/propertyTypes';
import {
  SHEET_LAYOUT_COL,
  SHEET_STAT_AVG,
  SHEET_STAT_MAX,
  SHEET_STAT_MIN,
  SHEET_TYPE_ADJUST,
  SHEET_TYPE_SIMPLE
} from './constants';
import produce from 'immer';

export const openSheet = createAsyncThunk(
  'sheet/open',
  async (id, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const d = await getDoc(doc(getFirestore(), 'orgs', org, 'sheets', id));
      return Sheet.create(d);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const createSheet = createAsyncThunk(
  'sheet/create',
  async (sheet, {getState, rejectWithValue}) => {
    const auth = getState().auth;
    const comps = getState().cart.comps;
    const id = getState().sheet.create.id;

    try {
      const s = cloneDeep(Sheet.map(sheet));
      if (s.styles.base.font) {
        const f = lookupFont(s.styles.base.font.family, MICROSOFT_FONTS);
        if (f) {
          s.styles.base.font.family = f.name;
        }
      }

      const result = await httpsCallable(getFunctions(), 'createSheet')({
        org: auth.org.id,
        sheet: s,
        id,
        comps,
        fonts: FONTS
      });
      if (result.data.status === "success") {
        const path = result.data.path;
        const url = await getDownloadURL(ref(getStorage(), path));
        return {sheet, url, path, id: result.data.id};
      }
      else {
        return rejectWithValue(result.data);
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const saveSheet = createAsyncThunk(
    'sheet/save',
    async (sheet, {getState, dispatch, rejectWithValue}) => {
      const auth = getState().auth;
      const comps = getState().cart.comps;
      const {url, path} = getState().sheet.create;
      try {
        const now = new Date();
        await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'sheets'), {
          ...Sheet.map(sheet),
          url, path,
          comps: comps.map(c => c.id),
          created: now,
          created_by: {
            name: auth.user.displayName,
            email: auth.user.email
          },
          updated: now,
        });
      }
      catch(err) {
        return rejectWithValue(err);
      }
    }
)

export const deleteSheet = createAsyncThunk(
  'sheet/delete',
  async (sheet, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      await deleteDoc(doc(getFirestore(), 'orgs', org, 'sheets', sheet.id));
      return sheet.id;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const exportSheet = createAsyncThunk(
  'sheet/export',
  async (sheet, {rejectWithValue}) => {
    try {
      if (sheet.url) return sheet.url;

      return await getDownloadURL(ref(getStorage(), sheet.path));
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const pageSheets = createAsyncThunk(
  'sheet/page',
  async (forward, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    const list = getState().sheet.list;
    const newPage = list.page+(forward ? 1 : -1);

    try {
      const constraints = [
        where('type', 'in', [SHEET_TYPE_SIMPLE, SHEET_TYPE_ADJUST]),
        orderBy(list.sort.field, list.sort.order),
        limit(list.size)
      ];
      if (list.page > -1) {
        if (forward) {
          constraints.push(startAfter(list.last));
        }
        else {
          constraints.push(startAt(last(list.stack)));
        }
      }
      const result = await getDocs(query(
        collection(getFirestore(), 'orgs', org, 'sheets'),
        ...constraints
      ));
      return {docs: result.docs, page: newPage};
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadTemplates = createAsyncThunk(
  'sheet/templates/load',
  async (templateId, {dispatch, getState, rejectWithValue}) => {
      const org = getState().auth.org.id;
      try {
        const result = await getDocs(query(
          collection(getFirestore(), 'orgs', org, 'sheetTemplates'),
          orderBy('updated', 'desc')
        ));
        const templates = result.docs.map(SheetTemplate.create);
        if (templateId) {
          dispatch(chooseTemplate(find(templates, it => it.id === templateId)));
        }
        return templates;
      }
      catch(err) {
        return rejectWithValue(err);
      }
  }
);

export const chooseTemplate = createAsyncThunk(
  'sheet/templates/choose',
  (template, {dispatch, getState, rejectWithValue}) => {
    try {
      const {sheet} = getState();
      const t = typeof template === 'string' ? find(sheet.templates.all, it => it.id === template) : template;
      dispatch(applyTemplate(Sheet.unmap(t ? t : initSheet())));
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

const mapTemplate = template => {
  return {...produce(template, td => {
    delete td.id;
    delete td.dirty;

    td.fields = (template.fields||[]).map(Template.mapField);
    if (template.time && template.time.field) {
      td.time.field = Template.mapField(template.time.field);
    }
    if (template.unit ) {
      td.unit = Template.mapField(template.unit);
    }
    if (template.metrics && template.metrics.fields) {
      td.metrics.fields = template.metrics.fields.map(Template.mapField);
    }
  })};
};

export const addTemplate = createAsyncThunk(
  'sheet/templates/add',
  async ({sheet, name}, {getState, rejectWithValue}) => {
    const {auth, cart} = getState();
    const now = new Date();

    try {
      const t = mapTemplate(sheet);
      t.title = name;
      t.types = uniq(cart.comps.map(it => it.type));
      t.created = now;
      t.created_by = {
        name: auth.user.displayName,
        email: auth.user.email
      };
      t.updated = now;
      const doc = await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'sheetTemplates'), t);
      return doc.id;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const copyTemplate = createAsyncThunk(
    'sheet/templates/copy',
    async ({template, title}, {getState, rejectWithValue}) => {
      const {auth} = getState();
      const now = new Date();

      try {
        const t = {...template}
        t.title = title;
        t.created = now;
        t.created_by = {
          name: auth.user.displayName,
          email: auth.user.email
        };
        t.updated = now;
        t.copy_of = template.id;

        const ref = await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'sheetTemplates'), t);
        await updateDoc(ref, {id: ref.id});
        return ref.id;
      }
      catch(err) {
        return rejectWithValue(err);
      }
    }
);

export const saveTemplate = createAsyncThunk(
  'sheet/templates/save',
  async (arg, {getState, rejectWithValue}) => {
    const {auth, sheet} = getState();
    const now = new Date();

    try {
      const t = omitBy(mapTemplate(sheet.curr), isNil);
      t.id = sheet.templates.curr.id;
      t.title = sheet.templates.curr.title;
      t.updated = now;
      t.updated_by = {
        name: auth.user.displayName,
        email: auth.user.email
      };

      await updateDoc(doc(getFirestore(), 'orgs', auth.org.id, 'sheetTemplates', t.id), t);
    }
    catch(err) {
      console.log(err);
      return rejectWithValue(err);
    }
  }
);

export const removeTemplate = createAsyncThunk(
  'sheet/templates/remove',
  async (templateId, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      await deleteDoc(doc(getFirestore(), 'orgs', org, 'sheetTemplates', templateId));
      return templateId;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

const initTime = () => ({
  enabled: false,
  name: "Time Adjustment",
  field: null,
  date: null,
  appreciation: null
});

const initFont = (config={}) => merge({
  font: {
    family: 'arial',
    size: 10,
    weight: WEIGHT_NORMAL,
    style: STYLE_NORMAL,
    transform: TRANSFORM_NONE,
    decoration: DECORATION_NONE,
    color: ALMOST_BLACK,
    align: ALIGN_LEFT
  },
  background: WHITE
}, config);

const initSheet = () => ({
  type: SHEET_TYPE_ADJUST,
  id: null,
  template: null,
  preset: null,
  dirty: false,
  title: "",
  subject: {
    enabled: false
  },
  fields: [],
  adjusts: {
    enabled: true,
    columns: [],
    qualitative: {
      symbols: false,
      similar_threshold: 0,
      far_threshold: 15
    }
  },
  metrics: {
    enabled: true,
    fields: [],
    stats: [{
      enabled: true,
      type: SHEET_STAT_MIN,
      label: "Minimum",
    }, {
      enabled: true,
      type: SHEET_STAT_MAX,
      label: "Maximum"
    }, {
      enabled: true,
      type: SHEET_STAT_AVG,
      label: "Average"
    }]
  },
  unit: null,
  time: initTime(),
  styles: {
    base: initFont({}),
    index: {
      head: initFont({
        font: { weight: WEIGHT_BOLD }
      }),
      body: initFont({
        font: { weight: WEIGHT_BOLD }
      }),
    },
    field: {
      head: initFont({
        font: { weight: WEIGHT_BOLD }
      }),
      body: initFont(),
    },
    unit: {
      head: initFont({
        font: {
          weight: WEIGHT_BOLD
        }
      }),
      body: initFont({
        font: {
          weight: WEIGHT_BOLD
        }
      })
    },
    adjust: {
      head: initFont({
        font: {
          weight: WEIGHT_BOLD,
        }
      }),
      body: initFont()
    },
    time: {
      head: initFont({
        font: {
          weight: WEIGHT_BOLD,
        }
      }),
      body: initFont()
    },
    total: {
      head: initFont({
        font: {
          weight: WEIGHT_BOLD,
        }
      }),
      body: initFont()
    },
    final: {
      head: initFont({
        font: {
          style: STYLE_ITALIC,
          weight: WEIGHT_BOLD
        }
      }),
      body: initFont({
        font: {
          style: STYLE_ITALIC,
          weight: WEIGHT_BOLD
        }
      })
    },
    metric: {
      head: initFont({
        font: {style: STYLE_ITALIC}
      }),
    },
    subject: {
      head: initFont({
        font: { weight: WEIGHT_BOLD }
      }),
      body: initFont({
        font: { weight: WEIGHT_BOLD }
      }),
    },
    header: initFont(),
    footer: initFont(),
    extra: []
  },
  labels: {
    total_quantitative: "Total Adjustments",
    total_qualitative: "Overall Comparison",
    qualitative_superior: "Superior",
    qualitative_far_superior: "Far Superior",
    qualitative_inferior: "Inferior",
    qualitative_far_inferior: "Far Inferior",
    qualitative_similar: "Similar",
  },
  layout: SHEET_LAYOUT_COL,
  footer: '',
  noun: '',
  roundToDollar: false,
});

const sheet = createSlice({
  name: 'sheet',
  initialState: {
    list: {
      page: -1,                           // page index
      size: 10,                           // page size
      values: [],                         // page contents
      last: null,
      stack: [],
      sort: {
        field: "created",
        order: "desc"
      },
    },
    curr: initSheet(),
    create: {
      id: null,
      path: null,
      url: null,
    },
    error: null,
    templates: {
      curr: {
        id: "",
        title: "",
        dirty: false,
        new: false,
      },
      all: null
    },
    view: {
      tabs: {
        curr: 0,
        last: -1,
        layout: {
          accordion: null,
        }
      },
    }
  },
  reducers: {
    newSheet: (state, action) => {
      const now = new Date();
      state.curr.id = '';
      state.curr.title = '';
      state.curr.type = SHEET_TYPE_ADJUST;
      state.curr.template = null;
      state.curr.fields = [];
      state.curr.time = {
        ...initTime,
        date: new Date(now.getFullYear(), now.getMonth(), 1)
      };
      state.curr.unit = null;
      state.curr.adjusts = {
        enabled: true,
        columns: []
      };
      state.curr.dirty = true;
      state.templates.curr.id = '';
      state.templates.curr.title = '';
      state.templates.curr.dirty = false;
    },

    setValue: (state, action) => {
      const {key, path, value} = action.payload;
      if (path) {
        set(state.curr, path, value);
      }
      else {
        state.curr[key] = value;
      }
      state.curr.dirty = true;
    },

    addFields: (state, action) => {
      const fields = action.payload;
      const f = (fields || []).filter(it => !state.curr.fields.includes(it))
      state.curr.fields.push(...f);
      state.curr.dirty = true;
    },

    removeField: (state, action) => {
      const field = action.payload;
      const i = state.curr.fields.findIndex(f => f.path === field.path);
      const u = state.curr.unit ? state.curr.fields.findIndex(f => f.path === state.curr.unit.path) : -1;

      state.curr.fields.splice(i, 1);
      if (u === i) {
        state.curr.unit = null;
      }
      state.curr.dirty = true;
    },

    moveField: (state, action) => {
      const {from, to} = action.payload;
      const f = state.curr.fields[from];
      state.curr.fields.splice(from, 1);
      state.curr.fields.splice(to, 0, f);
      state.curr.dirty = true;
    },

    selectField: (state, action) => {
      const index = action.payload;
      state.curr.unit = state.curr.fields[index];
      state.curr.dirty = true;
    },

    renameField: (state, action) => {
      const {field, label} = action.payload;
      if (!state.curr.labels) state.curr.labels = {};
      state.curr.labels[field.path] = label;
    },

    toggleAdjusts: (state, action) => {
      state.curr.adjusts.enabled = !state.curr.adjusts.enabled;
      state.curr.dirty = true;
    },

    setLabel: (state, action) => {
      const {key, value} = action.payload;
      if (!state.curr.labels) state.curr.labels = {};
      state.curr.labels[key] = value;
      state.curr.dirty = true;
    },

    addAdjust: (state, action) => {
      state.curr.adjusts.columns.push({ id: nanoid(), name: ''});
      state.curr.dirty = true;
    },

    updateAdjust: (state, action) => {
      const {id, key, value} = action.payload;
      const i = state.curr.adjusts.columns.findIndex(a => a.id === id);
      if (i > -1) {
        state.curr.adjusts.columns[i][key] = value;
        state.curr.dirty = true;
      }
    },

    removeAdjust: (state, action) => {
      const index = action.payload;
      state.curr.adjusts.columns.splice(index, 1);
      state.curr.dirty = true;
    },

    moveAdjust: (state, action) => {
      const {from, to} = action.payload;
      const a = state.curr.adjusts.columns[from];
      state.curr.adjusts.columns.splice(from, 1);
      state.curr.adjusts.columns.splice(to, 0, a);
      state.curr.dirty = true;
    },

    setAdjustValue: (state, action) => {
      const {path, value} = action.payload;
      set(state.curr.adjusts, path, value);
    },

    toggleTimeAdjust: (state, action) => {
      state.curr.time.enabled = !state.curr.time.enabled;
      state.curr.dirty = true;
    },

    setTimeAdjustValue: (state, action) => {
      const {key, value} = action.payload;
      state.curr.time[key] = value;
      state.curr.dirty = true;
    },

    toggleMetrics: (state, action) => {
      state.curr.metrics.enabled = !state.curr.metrics.enabled;
      state.curr.dirty = true;
    },

    addMetricField: (state, action) => {
      state.curr.metrics.fields.push(action.payload);
      state.curr.dirty = true;
    },

    removeMetricField: (state, action) => {
      const i = state.curr.metrics.fields.findIndex(f => f.path === action.payload.path);
      if (i > -1) {
        state.curr.metrics.fields.splice(i, 1)
        state.curr.dirty = true;
      }
    },

    toggleMetricStat: (state, action) => {
      const s = state.curr.metrics.stats.findIndex(it => it.type === action.payload);
      if (s > -1) {
        state.curr.metrics.stats[s].enabled = !state.curr.metrics.stats[s].enabled;
        state.curr.dirty = true;
      }
    },

    setMetricStatLabel: (state, action) => {
      const {stat, label} = action.payload;
      const s = state.curr.metrics.stats.findIndex(it => it.type === stat);
      if (s > -1) {
        state.curr.metrics.stats[s].label = label;
        state.curr.dirty = true;
      }
    },

    updateStyle: (state, action) => {
      const {path, value} = action.payload;
      set(state.curr.styles, path, value);
      state.curr.dirty = true;
    },

    addExtraStyle: (state, action) => {
      state.curr.styles.extra.push({
        field: null,
        config: {
          head: initFont(),
          body: initFont(),
        }
      });
      state.curr.dirty = true;
    },

    removeExtraStyle: (state, action) => {
      state.curr.styles.extra.splice(action.payload, 1);
      state.curr.dirty = true;
    },

    choosePreset: (state, action) => {
      let fields = [];
      let adjusts = [];
      switch(action.payload) {
        case ICI:
          fields = [Field.TITLE, Field.ADDRESS_CITY, Field.PHYSICAL_PROPERTY_TYPE, Field.PHYSICAL_EFFECTIVE_LAND_AREA_SF,
            Field.IMPROVEMENT_YEAR_BUILT, Field.IMPROVEMENT_PROPERTY_CONDITION, Field.SALES_DATE,
            Field.SALES_PRICE, Field.FINANCIAL_CAP_RATE, Field.SALES_PRICE_PER_SQFT]
          // unit = Field.SALES_PRICE_PER_SQFT;
          adjusts = ["Lot Size", "Location", "Age/Condition", "Building Size", "Zoning"].map(name => {
            return {id:nanoid(), name}
          });
          break;
        case VACANT_LAND:
          fields = [Field.TITLE, Field.ADDRESS_CITY, Field.SALES_PRICE, Field.SALES_DATE,
            Field.PHYSICAL_TOTAL_LAND_AREA_SF, Field.SALES_PRICE_PER_SF_TOTAL,
            Field.PHYSICAL_TOTAL_LAND_AREA_ACRE, Field.SALES_PRICE_PER_ACRE_TOTAL]
          // unit = Field.SALES_PRICE_PER_ACRE_TOTAL;
          adjusts = ["Size", "Location", "Position", "Shape", "Zoning", "Site Prep", "Imp.", "Serv.", "Other"].map(name => {
            return {id:nanoid(), name}
          });
          break;
        case RESIDENTIAL_RENT:
          fields = [Field.TITLE, Field.ADDRESS_CITY, Field.PHYSICAL_PROPERTY_TYPE, Field.IMPROVEMENT_YEAR_BUILT,
            Field.IMPROVEMENT_PROPERTY_CONDITION, Field.IMPROVEMENT_NUM_BEDROOMS, Field.IMPROVEMENT_NUM_BATHROOMS,
            Field.FINANCIAL_MONTHLY_RENT];
          // unit = Field.FINANCIAL_MONTHLY_RENT;
          adjusts = ["Size", "Location", "Age/Condition", "# Beds", "# Baths", "Bsmt", "Parking"].map(name => {
            return {id:nanoid(), name}
          });
          break;
        case LEASE:
          fields = [Field.TITLE, Field.ADDRESS_CITY, Field.PHYSICAL_PROPERTY_TYPE, Field.IMPROVEMENT_YEAR_BUILT,
            Field.IMPROVEMENT_PROPERTY_CONDITION, Field.LEASE_START_DATE, Field.IMPROVEMENT_LEASED_AREA,
            Field.LEASE_AVERAGE_RATE_OVER_TERM]
          // unit = Field.LEASE_AVERAGE_RATE_OVER_TERM;
          adjusts = ["Size", "Location", "Age/Condition", "Parking"].map(name => {
            return {id:nanoid(), name}
          });
          break;
        case MULTI_FAMILY:
          fields = [Field.TITLE, Field.ADDRESS_CITY, Field.PHYSICAL_PROPERTY_TYPE, Field.PHYSICAL_EFFECTIVE_LAND_AREA_SF,
            Field.IMPROVEMENT_YEAR_BUILT, Field.IMPROVEMENT_PROPERTY_CONDITION, Field.IMPROVEMENT_NUM_UNITS, Field.SALES_DATE,
            Field.SALES_PRICE, Field.FINANCIAL_CAP_RATE, Field.SALES_PRICE_PER_UNIT]
          // unit = Field.SALES_PRICE_PER_UNIT;
          adjusts = ["Size", "Location", "Position", "Shape", "Site Prep" ,"Imp.", "Serv.", "Other"].map(name => {
            return {id:nanoid(), name}
          });
          break;
        default:
          break;
      }

      state.curr.preset = action.payload;
      state.curr.fields = fields;
      state.curr.adjusts = {enabled: true, columns: adjusts};
      state.curr.unit = fields[fields.length-1];
      state.curr.dirty = true;
    },

    sortSheets: (state, action) => {
      const {field, order} = action.payload;
      state.list.page = -1;
      state.list.sort = {field: field.path, order}
    },

    applyTemplate: (state, action) => {
      const t = cloneDeep(action.payload);
      const c = pick(state.curr, ["title"]);
      state.curr = merge(initSheet(), t, c);
      state.templates.curr.id = t.id || '';
      state.templates.curr.title = t.title || '';
      state.templates.curr.dirty = false;
    },

    changeTab: (state, action) => {
      state.view.tabs.last = state.view.tabs.curr;
      state.view.tabs.curr = action.payload;
    },

    popTab: (state, action) => {
      if (state.view.tabs.last > -1) {
        state.view.tabs.curr = state.view.tabs.last;
      }
    },

    updateView: (state, action) => {
      const {path, value} = action.payload;
      set(state.view, path, value);
    },

    patchTemplate: (state, action) => {
      const {id, patch} = action.payload;
      const t = (state.templates.all||[]).findIndex(t => t.id === id);
      if (t > -1) {
        state.templates.all[t] = merge(state.templates.all[t], patch);
      }
    },
  },
  extraReducers: builder =>
    builder
      .addCase(openSheet.pending, (state, action) => {
        state.curr = initSheet();
      })
      .addCase(openSheet.fulfilled, (state, action) => {
        state.curr = {
          ...initSheet(),
          ...action.payload
        };
        if (!state.curr.styles.metrics) {
          state.curr.styles = {
            ...initSheet().styles,
            ...state.curr.styles
          }
        }
      })
      .addCase(createSheet.pending, (state, action) => {
        state.create.url = null;
        state.create.path = null;
      })
      .addCase(createSheet.fulfilled, (state, action) => {
        state.create.id = action.payload.id;
        state.create.url = action.payload.url;
        state.create.path = action.payload.path;
        state.list.page = -1;
      })
      .addCase(deleteSheet.fulfilled, (state, action) => {
        state.list.values = (state.list.values||[]).filter(it => it.id !== action.payload);
      })
      .addCase(pageSheets.fulfilled, (state, action) => {
        const {page, docs} = action.payload;
        const list = state.list;
        const forward = page > state.list.page;
        const stack = list.stack.slice();
        if (forward) {
          stack.push(list.first);
        }
        else {
          stack.pop();
        }
        state.list.page = page;
        state.list.values = docs.map(doc => Sheet.create(doc));
        state.list.last = last(docs);
        state.list.first = first(docs);
        state.list.stack = stack;
      })
      .addCase(loadTemplates.fulfilled, (state, action) => {
        state.templates.all = action.payload;
      })
      .addCase(removeTemplate.fulfilled, (state, action) => {
        state.templates.all = (state.templates.all || []).filter(it => it.id !== action.payload);
        if (state.templates.curr && state.templates.curr.id === action.payload) {
          state.templates.curr.id = '';
          state.templates.curr.title = '';
          state.curr.template = null;
        }
      })
});

export const {
  newSheet, choosePreset, setValue, setFields, addFields, removeField, moveField, selectField, renameField,
  toggleAdjusts, addAdjust, removeAdjust, updateAdjust, moveAdjust, setAdjustValue, toggleTimeAdjust, setTimeAdjustValue, setLabel,
  toggleMetrics, addMetricField, removeMetricField, toggleMetricStat, setMetricStatLabel,
  updateStyle, addExtraStyle, removeExtraStyle, sortSheets, applyTemplate, changeTab, popTab, updateView,
  patchTemplate
} = sheet.actions;

export default sheet.reducer;
