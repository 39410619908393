import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";


const HelpDialog = ({title, open, onClose, children}) => {
    return (
        <Dialog open={open} onClose={onClose} scroll="paper" maxWidth="md">
            <DialogTitle>
              {title}
            </DialogTitle>
            <DialogContent dividers sx={{
              "& > p": {
                marginBottom: "1rem"
              },
            }}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default HelpDialog;
