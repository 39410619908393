import {doc, getDoc, getFirestore} from 'firebase/firestore';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {useHistory} from 'react-router';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import RefreshIcon from '@mui/icons-material/Refresh';
import TypeAvatar from '../comp/TypeAvatar';
import {Field} from '../model/fields';
import {Comp} from '../model/objects';
import ErrorAlert from '../util/ErrorAlert';
import {FormRow} from '../util/Form';
import Page from '../util/Page';
import Spinner from '../util/Spinner';
import ToolBar from '../util/ToolBar';
import DeleteDialog from './DeleteDialog';
import {pluralize} from '../util/utils';
import {pushSnack} from '../app/state';
import {deleteFromList, openList, popList, refreshList, saveList, setProp} from './state';

const ListPage = () => {
  const {id} = useParams();
  const history = useHistory();
  const auth = useSelector(state => state.auth);
  const list = useSelector(state => state.list.curr);
  const error = useSelector(state => state.list.error);
  const [comps, setComps] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeletingFromList, setDeletingFromList] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dispatch && id) {
      dispatch(openList(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    if (list && list.comps) {
      Promise.all(list.comps.map(compId => getDoc(doc(getFirestore(), 'orgs', auth.org.id, 'comps', compId))))
        .then(docs => setComps(docs.filter(d => d.exists()).map(Comp.create)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const handleSave = () => {
    dispatch(saveList())
      .then(() => {
        dispatch(pushSnack({message: `List ${list.title} saved.`}));
      })
  };

  const handleDelete = () => {
    setShowDeleteDialog(true);
  };

  const handleDeleteFromList = compId => {
    setDeletingFromList(true);
    dispatch(deleteFromList({id, compId}))
      .finally(() => setDeletingFromList(false));
  }

  return (
    <Page className="ListPage">
      {list ? <>
        <ToolBar title={list.title}>
          <IconButton size="large">
            <Tooltip title="Add List To Cart">
              <AddShoppingCartIcon onClick={() => dispatch(popList({list, appendToCart: true}))}/>
            </Tooltip>
          </IconButton>
          <IconButton size="large">
            <Tooltip title="Replace Cart with List">
              <RemoveShoppingCartIcon onClick={() => dispatch(popList({list, appendToCart: false}))}/>
            </Tooltip>
          </IconButton>
          <Tooltip title="Save Changes">
            <IconButton onClick={handleSave} size="large" className="SaveButton">
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete this List">
            <IconButton onClick={handleDelete} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ToolBar>
        <Container fixed>
          <Grid container>
            <Grid item>
              {error && <ErrorAlert error={error}/> }
            </Grid>
          </Grid>
          <FormRow>
            <Grid item md={6}>
              <TextField value={list.title} onChange={evt => dispatch(setProp({key:"title", value:evt.target.value}))}
                         label="List Title" variant="outlined" fullWidth/>
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item md={6}>
              <TextField value={list.description} onChange={evt => dispatch(setProp({key:"description", value:evt.target.value}))}
                         label="List Description" variant="outlined" fullWidth multiline
                         rows={4}/>
            </Grid>
          </FormRow>
          {list.comps && <FormRow>
            <Grid item xs={12}>
              <Paper>
                <Toolbar variant="dense" sx={{ pl: 2, pr: 1, bgcolor: 'grey.100', justifyContent: "space-between"}}>
                  <Typography variant="subtitle1">
                    {pluralize(list.comps.length, "comp")}
                  </Typography>
                  <Tooltip title="Refresh List">
                    <IconButton onClick={() => dispatch(refreshList(list.id))}>
                      <RefreshIcon/>
                    </IconButton>
                  </Tooltip>
                </Toolbar>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {(comps||[]).map(c => <TableRow key={c.id}>
                        <TableCell style={{width: '1%', verticalAlign: 'top'}}>
                          <TypeAvatar type={c.type}/>
                        </TableCell>
                        <TableCell style={{width: '1%'}}>
                          {(c.photos||[]).length > 0 && <Box component="img" src={c.photos[0].url} alt="" sx={{
                            width: 128,
                            height: 128,
                            objectFit: 'cover',
                            objectPosition: 'center'
                          }}/>}
                        </TableCell>
                        <TableCell style={{verticalAlign: 'top'}}>
                          <Link to={`/comp/${c.id}`}>
                            {c.title}
                          </Link>
                          <Typography variant="subtitle2">
                            {c.comp_id}
                          </Typography>
                          <Typography variant="caption" style={{fontStyle: 'italic'}}>
                            {Field.CONFIRMATION_STATUS.render(c)}
                          </Typography>
                        </TableCell>
                        <TableCell style={{verticalAlign: 'top'}}>
                          <Typography variant="body2">{Field.VALUE.render(c)}</Typography>
                          <Typography variant="subtitle2">
                            {Field.DATE.render(c)}
                          </Typography>
                        </TableCell>
                        <TableCell style={{verticalAlign: 'top'}}>
                          <IconButton
                            disabled={isDeletingFromList}
                            onClick={() => handleDeleteFromList(c.id)}
                            size="large">
                            {isDeletingFromList === false ? <Tooltip title="Delete Comp from List">
                              <DeleteIcon />
                            </Tooltip> : <CircularProgress size={24}/>}
                          </IconButton>
                        </TableCell>
                      </TableRow>)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </FormRow>}
        </Container>
      </> : <Spinner/>}
      {list && <DeleteDialog list={list} isOpen={showDeleteDialog} onClose={wasDeleted => {
        setShowDeleteDialog(false);
        if (wasDeleted === true) {
          history.replace('/');
        }
      }} />}
    </Page>
  );
};

export default ListPage;
