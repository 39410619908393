import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import DeleteIcon from "@mui/icons-material/Delete";
import {pushSnack} from '../app/state';
import ToolBar from "../util/ToolBar";
import Page from "../util/Page";
import DataTable from "../util/DataTable";
import {Field} from "../model/fields";
import DeleteDialog from './DeleteDialog';
import {pageLists, popList, sortLists} from './state';

const FIELDS =  [Field.TITLE, Field.UPDATED];

const ListActions = ({list}) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const handleDelete = () => {
        setShowConfirmDelete(true);
    }

    return <>
        <IconButton size="large">
            <Tooltip title="Add List To Cart">
                <AddShoppingCartIcon onClick={() => dispatch(popList({list, appendToCart: true}))}/>
            </Tooltip>
        </IconButton>
        <IconButton size="large">
            <Tooltip title="Replace Cart with List">
                <RemoveShoppingCartIcon onClick={() => dispatch(popList({list, appendToCart:false}))}/>
            </Tooltip>
        </IconButton>
        <IconButton onClick={handleDelete} size="large">
          <Tooltip title="Delete List">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
        {showConfirmDelete && <DeleteDialog isOpen={showConfirmDelete} onClose={result => {
          if (result === true) {
            dispatch(pushSnack({message: `List ${list.title} has been deleted.`}));
          }
          setShowConfirmDelete(false)
        }} auth={auth} list={list}/>}
    </>;
}

const ListsPage = () => {
    const list = useSelector(state => state.list.list);
    const numLists = useSelector(state => state.app.org.num_lists || 0);
    const dispatch = useDispatch();

    useEffect(() => {
        if (list.page === -1) {
            dispatch(pageLists(true));
        }
    }, [list, dispatch]);

    const handlePage = forward => {
        return dispatch(pageLists(forward));
    };

    const handleSort = (field, order) => {
        return dispatch(sortLists({field, order}));
    };
    return (
        <Page fluid={true} className="ListsPage">
            <ToolBar title="Lists">
            </ToolBar>
            <Container fixed>
                <DataTable fields={FIELDS} list={list} numItems={numLists} onPage={handlePage} onSort={handleSort} noun="list"
                           createSubtitle={list => <Typography variant="subtitle2">
                               {`${list.comps.length} Comps`}
                           </Typography>}
                           createActions={list => <ListActions list={list}/>}
                           createItemLink={list => `/list/${list.id}`}
                    // styleFieldCell={(field, i) => i === 0 ? {width: "50%"}: {}}
                />
            </Container>
        </Page>
    )
}

export default ListsPage;

