import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  addDoc,
  collection,
  deleteDoc,
  doc, getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query, startAfter, startAt,
  updateDoc
} from 'firebase/firestore';
import {first, last, uniq} from 'lodash';
import {addCompIdsToCart, clearCart} from '../cart/state';
import {Field} from '../model/fields';
import {List} from '../model/objects';
import {MODE_CREATE} from './constants';

export const loadLists = createAsyncThunk(
  'list/load',
  async (arg, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const result = await getDocs(query(
        collection(getFirestore(), 'orgs', org, 'lists'),
        orderBy(Field.UPDATED.path, "desc"),
        limit(25),
      ));
      return result.docs.map(List.create)
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const addList = createAsyncThunk(
  'list/add',
  async (arg, {getState, rejectWithValue}) => {
    const {cart, list, auth} = getState();
    try {
      const newList = {
        ...list.curr,
        comps: cart.comps.map(c => c.id),
        created: new Date(),
        updated: new Date(),
      };

      await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'lists'), newList);
      return newList;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const saveList = createAsyncThunk(
  'list/save',
  async (arg, {getState, rejectWithValue}) => {
    const {auth, list} = getState();
    try {
      const newList = {
        ...list.curr,
        updated: new Date()
      };
      await updateDoc(doc(getFirestore(), 'orgs', auth.org.id, 'lists', list.curr.id), newList);
      return newList;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const updateList = createAsyncThunk(
  'list/update',
  async (replace, {getState, rejectWithValue}) => {
    const {auth, list, cart} = getState();
    try {
      const newComps = cart.comps.map(c => c.id);
      const newList = {
        ...list.curr,
        comps: replace ? newComps : uniq((list.curr.comps||[]).concat(newComps)),
        updated: new Date()
      };

      await updateDoc(doc(getFirestore(), 'orgs', auth.org.id, 'lists', list.curr.id), newList);
      return newList;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteList = createAsyncThunk(
  'list/delete',
  async (list, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      await deleteDoc(doc(getFirestore(), 'orgs', org, 'lists', list.id));
      return list;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const popList = createAsyncThunk(
  'list/pop',
  async ({list, appendToCart}, {dispatch, getState, rejectWithValue}) => {
    try {
      if (!appendToCart) dispatch(clearCart());
      await dispatch(addCompIdsToCart(list.comps));
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const pageLists = createAsyncThunk(
  'list/page',
  async (forward, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    const list = getState().list.list;

    try {
      const newPage = list.page+(forward ? 1 : -1);
      const constraints = [
        orderBy(list.sort.field, list.sort.order),
        limit(list.size)
      ];
      if (list.page > -1) {
        if (forward) {
          constraints.push(startAfter(list.last));
        }
        else {
          constraints.push(startAt(last(list.stack)));
        }
      }

      const result = await getDocs(query(collection(getFirestore(), 'orgs', org, 'lists'), ...constraints));
      return {page: newPage, docs: result.docs}
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const openList = createAsyncThunk(
  'list/open',
  async (id, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;

    try {
      const d = await getDoc(doc(getFirestore(), 'orgs', org, 'lists', id));
      return List.create(d);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const refreshList = createAsyncThunk(
  'list/refresh',
  async (id, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;

    try {
      const listRef = doc(getFirestore(), 'orgs', org, 'lists', id);
      const list = await getDoc(listRef);
      const compIds = list.get('comps');
      const comps = await Promise.all(compIds.map(compId => getDoc(doc(getFirestore(), 'orgs', org, 'comps', compId))));
      const liveCompIds = comps.filter(c => c.exists).map(c => c.id);
      await updateDoc(listRef, {comps: liveCompIds, updated: new Date()});
      return {comps: liveCompIds};
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteFromList = createAsyncThunk(
  'list/deleteFrom',
  async ({id, compId}, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;

    try {
      const ref = doc(getFirestore(), 'orgs', org, 'lists', id);
      const d = await getDoc(ref);
      await updateDoc(ref, {
        comps: (d.get('comps')||[]).filter(it => it !== compId)
      });
      return {id, compId};
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);


const list = createSlice({
  name: 'list',
  initialState: {
    all: [],
    list: {
      page: -1,       // page index
      size: 10,       // page size
      values: [],     // page contents
      last: null,
      stack: [],
      sort: {
        field: "updated",
        order: "desc"
      },
    },
    mode: MODE_CREATE,
    curr: {
      title: "",
      description: ""
    },
  },
  reducers: {
    chooseList: (state, action) => {
      state.curr = (state.all||[]).find(it => it.id === action.payload);
    },
    sortLists: (state, action) => {
      const {field, order} = action.payload;
      state.list.page = -1;
      state.list.sort = {field: field.path, order}
    },

    setMode: (state, action) => {
      state.mode = action.payload;
    },

    setProp: (state, action) => {
      const {key, value} = action.payload;
      state.curr[key] = value;

      if (state.list.values) {
        const i = state.list.values.findIndex(it => it.id === state.curr.id);
        if (i > -1) {
          state.list.values[i][key] = value;
        }
      }
    }
  },
  extraReducers: builder =>
    builder
      .addCase(loadLists.fulfilled, (state, action) => {
        state.all = action.payload;
      })
      .addCase(addList.fulfilled, (state, action) => {
        state.list.page = -1;
      })
      .addCase(saveList.fulfilled, (state, action) => {
        state.list.page = -1;
      })
      .addCase(updateList.fulfilled, (state, action) => {
        state.list.page = -1;
      })
      .addCase(deleteList.fulfilled, (state, action) => {
        state.list.values = (state.list.values||[]).filter(it => it.id !== action.payload.id);
      })
      .addCase(pageLists.fulfilled, (state, action) => {
        const {page, docs} = action.payload;
        const list = state.list;
        const forward = page > state.list.page;
        const stack = list.stack.slice();
        if (forward) {
          stack.push(list.first);
        }
        else {
          stack.pop();
        }
        state.list.page = page;
        state.list.values = docs.map(doc => List.create(doc));
        state.list.last = last(docs);
        state.list.first = first(docs);
        state.list.stack = stack;
      })
      .addCase(openList.fulfilled, (state, action) => {
        state.curr = action.payload;
      })
      .addCase(deleteFromList.fulfilled, (state, action) => {
        const {id, compId} = action.payload;
        state.list.page = -1;
        if (state.curr && state.curr.id === id) {
          state.curr.comps = (state.curr.comps||[]).filter(c => c !== compId);
        }
      })
      .addCase(refreshList.fulfilled, (state, action) => {
        if (state.curr) {
          state.curr.comps = action.payload;
        }
      })
});

export const { chooseList, setMode, setProp, sortLists } = list.actions;

export default list.reducer;
